import React from 'react'
import styled from 'styled-components'
const slugify = require('slugify')

const Input = styled.input`
  display: block;
  width: 100%;
  padding: ${props => props.theme.remScale[2]}
    ${props => props.theme.remScale[3]};
  border: 1px solid ${props => props.theme.neutral5};
  border-radius: 3px;
  font-size: ${props => props.theme.remScale[4]};
  transition: all 200ms;

  &.w-half {
    width: 50%;
  }
  &.w-third {
    width: 33%;
  }

  &:focus {
    border-color: ${props => props.theme.primary1};
    outline-color: ${props => props.theme.primary1};
  }

  &:invalid {
    width: calc(100% - 8px);
    border-right-width: 0;
    box-shadow: 8px 0 0 0 ${props => props.theme.danger5};

    &.w-half {
      width: calc(50% - 8px);
    }
    &.w-third {
      width: calc(33% - 8px);
    }

    &:focus {
      width: 100%;
      box-shadow: 0 0 0 0 transparent;
      border-right-width: 1px;

      &.w-half {
        width: 50%;
      }
      &.w-third {
        width: 33%;
      }
    }
  }

  &::placeholder {
    color: ${props => props.theme.neutral4};
  }
`

export const TextInput = ({ question, name, type, ...props }) => (
  <label>
    {question}
    <Input
      name={name || slugify(question, { lower: true })}
      type={type || 'text'}
      {...props}
    />
  </label>
)

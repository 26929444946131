import styled from 'styled-components'

export const StyledForm = styled.form`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: ${props => props.theme.breakpoints.md};
    margin-left: auto;
    margin-right: auto;
  }

  .invalid-only {
    transition: all 200ms ease;
  }
  &:valid .invalid-only {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
  }

  [type='submit'] {
    margin-bottom: ${props => props.theme.remScale[4]};
  }
  &:invalid [type='submit'] {
    background-color: ${props => props.theme.neutral5};
    box-shadow: none;
    color: ${props => props.theme.neutral2};
    text-shadow: none;
    cursor: not-allowed;
  }
`

import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles/Container'
import { ContactForm } from '../components/ContactForm'
import contactUs from '../images/undraw_contact_us_15o2.svg'

const ContactUs = () => (
  <Layout backgroundImage={contactUs}>
    <SEO title="Contact Us" description="Reach out to us with any questions you may have." />
    <Container boxed={true}>
      <h1>Contact Us</h1>
      <p>
        If you have any questions, don't hesitate to reach out! Submit the
        contact form below, and we'll get back to you as soon as we can.
      </p>
      <ContactForm />
    </Container>
  </Layout>
)

export default ContactUs

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from './styles/Button'
import { InfoMessage } from './styles/InfoMessage'
import { StyledForm } from './styles/StyledForm'
import { TextInput } from './TextInput'

export const ContactForm = () => (
  <StyledForm
    name="contact-us"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="fax-number"
    action="/contact-thank-you"
  >
    <input type="hidden" name="form-name" value="contact-us" />

    <div style={{ display: 'none' }}>
      <TextInput question="Fax Number" autocomplete="off" />
    </div>

    <h3>Send Us a Message</h3>

    <InfoMessage>
      <FontAwesomeIcon icon="exclamation-circle" /> Fields marked with a thick
      border on the right must be completed.
    </InfoMessage>

    <TextInput question="Name" required />
    <TextInput question="Email Address" type="email" required />
    <TextInput
      question="Primary Phone Number"
      type="tel"
      required
      pattern="^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$"
      className="w-half"
    />
    <TextInput question="Company Name" required />
    <TextInput as="textarea" rows="12" question="Message" required />

    <Button as="button" type="submit">
      Send Your Message <FontAwesomeIcon icon="paper-plane" />
    </Button>

    <InfoMessage className="info invalid-only" variant="danger">
      <FontAwesomeIcon icon="exclamation-circle" /> Woah, there! Please complete
      all the required fields.
    </InfoMessage>
  </StyledForm>
)

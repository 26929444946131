import styled, { css } from 'styled-components'

export const InfoMessage = styled.p`
  color: ${props => props.theme.neutral2};
  font-size: ${props => props.theme.remScale[4]};
  ${props =>
    props.variant === 'danger' &&
    css`
      color: ${props => props.theme.danger1};
    `}

  .svg-inline--fa {
    margin-right: ${props => props.theme.emScale[0]};
  }
`
